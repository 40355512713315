<template>
  <div>
    <qrcode-stream 
      @decode="onDecode" 
    />
    <v-text-field
      v-model="id"
      label="ID"
    />
    <v-btn
      @click="handleAdd"
    >
      A�adir
    </v-btn>  
  </div>
</template>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
  components: {
    QrcodeStream,
  },
  data: () => ({
    id: null,
  }),
  methods: {
    onDecode (v) {
      this.id = v
    },
    handleAdd () {
      alert(this.id)
    },
  },
}
</script>

